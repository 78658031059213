
import React from "react";

// CORE COMPONENTS
import CardsFooter from "components/Footers/CardsFooter.js";

// INDEX PAGE SECTIONS
import Carousel from "./IndexSections/Carousel.js";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

  }
  render() {
    return (
      <>
        <main ref="main">
          <Carousel />
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Index;
