import React from "react";
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer has-cards">
          <Container>
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                    12AM WORLDWIDE INC
                </div>
              </Col>
              <Col md="6">
                <Nav className="nav-footer justify-content-end">
                <NavItem>
                    <NavLink
                      href="mailto:contact@12amworldwide.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CONTACT
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
