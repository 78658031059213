import React from "react";
import { 
  Container, 
  Row, 
  Col, 
  UncontrolledCarousel 
} from "reactstrap";

const items = [
  {
    src: require("assets/img/logo/logo_twelve_am.jpg"),
    altText: "",
    caption: "",
    header: ""
  }
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <section className="section">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
   
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
            <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className="text-black font-weight-light">
                  12am Worldwide
                </h1>
                <p className="lead text-black mt-4">
                  Entertainment content, app platform and web3 developers. We create cross-platform and native experiences for a wide range of media types.
                  <br></br>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Carousel;
